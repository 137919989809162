
























































import { Component, Vue, Watch } from "vue-property-decorator";
import { ActionMethod } from 'vuex';
import { Action, Getter, State } from 'vuex-class';
import Utils from '@/plugins/utils'

@Component
export default class OrderPage extends Vue {
  private loading: Boolean = true;
  public errorMessage: String;

  @Getter('shopifyOrders/getOrderInfo') orderInfo: any;
  
  @Action('fetchOrderInfo', { namespace: 'shopifyOrders' }) fetchOrderInfo: ActionMethod;

  @Watch('$route.query') onRouteChanged() {
    this.$nextTick(() => {
      this.initPage()
    })
  }

  async mounted() {
    this.initPage()
  }

  get status() {
    if(this.orderInfo) {
      switch(this.orderInfo.status) {
        case 'ACCEPTED_BY_RECEIVER': return 'Accepted by receiver';
        case 'ACCEPTING': return 'Shipped';
        case 'CREATED': return 'Processing';
        default: return Utils.capitalize(Utils.lowerize(Utils.decamelize(this.orderInfo.status.trim())));
      }
    }
  }

  async initPage() {
    try {
      await this.fetchOrderInfo(this.$route.query.id) 
    } catch (err) { 
      if(err.message == 'component not found') {
        this.errorMessage = 'No order found.'
        this.$notify({ type: 'error', text: err.message }) 
      } else if(err.message == 'Error fetching Cainiao order information') {
        this.errorMessage = 'The order has not been synchronized between Tmall and Cainiao yet.'
      }
    }
    finally {
      this.loading = false
    }
  }
}
